<template>
  <div class="wameed-dashboard-page-content">


      <div class="user-detail-cards-4 mb-5" >
        <financial-card
            class="w-100"
            :number="getUserInfo.total_sales"
            :title="$t('financial.card.total_sales')"
            icon="f-profit2-icon"
        />

        <financial-card
            class="w-100"
            :number="getUserInfo.his_profit"
            :title="$t('financial.card.driver_profit')"
            icon="f-profit-minus-icon"
        />

        <financial-card
            class="w-100"
            :number="getUserInfo.our_profit"
            :title="$t('financial.card.our_from_delivery_profit')"
            icon="f-profit-plus-icon"
        />


        <financial-card
            class="w-100"
            :number="getUserInfo.total_orders"
            :title="$t('financial.card.total_orders')"
            icon="f-orders2-icon"
            :is_price="false"
        />

    </div>

      <b-col cols="12" md="6" class="p-0">

        <b-card
            no-body class=" wameed-card  m-0">

          <div class="p-5">

            <div class="tile-text between mb">
              <div class="text">{{ $t('users.driver.table.name') }}</div>
              <div class="value">{{ getUserInfo.name }}</div>
            </div>


            <div class="tile-text between mb">
              <div class="text">{{ $t('users.table.phone') }}</div>
              <div class="value">{{ getUserInfo.phone }}</div>
            </div>

            <div class="tile-text between mb">
              <div class="text">{{ $t('users.driver.table.delivery_type') }}</div>
              <div class="value">{{ getUserInfo.vehicle }}</div>
            </div>

          </div>
        </b-card>
      </b-col>
    </div>


  </div>
</template>

<script>
import AddressesModal from "@/views/pages/users/clients/components/addressesModal";

import {mapGetters} from 'vuex';
import FinancialCard from "@/components/financialCard";

export default {

  components: {
    FinancialCard,
    AddressesModal
  },
  data() {
    return {
      showImage: true,
      showAddressesModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserInfo: 'admin/users/detail/getUserInfo',
    }),


  },
  async created() {

    this.$store.dispatch('admin/users/detail/unsetData');
    await this.$store.dispatch('admin/users/detail/info', {
      id: this.$route.params.id,
      type: 'drivers'
    })
  },
  methods: {
    onError() {
      this.showImage = false;
    },

    geolocationLink(temp) {
      if (temp) {
        let location = temp.split(',');
        let locationLat = location[0];
        let locationLng = location[1];
        return `https://www.google.com/maps/place/${locationLat},${locationLng}/@${locationLat},${locationLng},15z`
      }
      return '';
    }

  },
};
</script>
